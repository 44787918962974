<template>
  <v-img
    :src="`/assets/image/contact-us_background${
      $vuetify.breakpoint.mobile ? '-mobile' : ''
    }.jpg`"
    class="contact-us"
  >
    <div class="content-wrapper d-flex align-center my-5 pt-2 py-sm-12">
      <!-- <router-link :to="{ name: routeName.HOME }">
        <v-img
          src="/assets/image/logo.png"
          :max-width="logoMaxWidth"
          alt="logo"
          class="mb-4 mb-md-6"
        ></v-img>
      </router-link> -->
    </div>
    <div
      class="pb-md-12"
      :class="{ 'content-wrapper': !$vuetify.breakpoint.mobile }"
    >
      <v-row no-gutters justify="space-between">
        <!-- Left Col Start -->
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center justify-md-start align-center mb-10 mb-md-0 px-8 px-md-0"
        >
          <div
            v-intersect="{
              handler: onIntersectContactForm,
              options: {
                threshold: 1.0
              }
            }"
          >
            <transition name="slide-fade-y">
              <div
                v-show="isShowContactForm"
                class="surface--text font-weight-bold text-body-1 text-sm-h6 text-md-h5 text-xl-h4 enter-delay-750ms"
              >
                {{ $t('label.wedLoveToHearFromYou') }}
              </div>
            </transition>
            <div
              id="contactUsTitleDivider"
              class="mt-2 mb-8 mb-xl-12 contact-us__title-divider"
            ></div>

            <v-form ref="contactForm">
              <template v-for="(i, index) in formFields">
                <transition :key="index" name="slide-fade-y">
                  <AppFormField
                    v-show="isShowContactForm"
                    v-model="i.value"
                    :type="i.type"
                    :solo="true"
                    :flat="true"
                    :rounded="true"
                    :rows="i.rows"
                    :placeholder="$t(`label.${i.placeholder}`)"
                    :rules="i.rules"
                    class="contact-us__form-field mb-xl-2"
                    :class="`enter-delay-${1 + (index + 1)}s`"
                  ></AppFormField>
                </transition>
              </template>
              <div class="px-4">
                <transition name="slide-fade-y">
                  <v-checkbox
                    v-show="isShowContactForm"
                    v-model="checkboxAgreement"
                    :rules="formValidation.requiredRules()"
                    dark
                    color="primary"
                    class="contact-us__checkbox enter-delay-4s"
                    on-icon="mdi-check-circle"
                    off-icon="mdi-check-circle"
                  >
                    <template v-slot:label>
                      <div
                        class="surface--text"
                        @click.stop
                        v-html="$t('message.agreementMessage')"
                      ></div>
                    </template>
                  </v-checkbox>
                </transition>
              </div>

              <transition name="slide-fade">
                <div
                  v-show="isShowContactForm"
                  class="text-center pt-sm-4 pt-md-6 enter-delay-5s"
                >
                  <AppButton
                    type="default"
                    :xLarge="!$vuetify.breakpoint.mobile"
                    :faded="submitButtonFaded"
                    class="contact-us__submit-button"
                    @click="submitAction"
                    >{{ $t('label.submit') }}</AppButton
                  >
                </div>
              </transition>
            </v-form>
          </div>
        </v-col>
        <!-- Left Col End -->

        <!-- Right Col Start -->
        <v-col
          v-intersect="{
            handler: onIntersectFindUsCard,
            options: {
              threshold: 1.0
            }
          }"
          cols="12"
          md="6"
        >
          <transition name="slide-fade-x">
            <v-card
              v-show="isShowFindUsCard"
              color="secondary"
              class="pa-10 rounded-0 rounded-md-lg enter-delay-6s"
            >
              <div
                class="surface--text font-weight-bold text-h5 text-xl-h4 mb-4"
              >
                {{ $t('label.findUsHere') }}
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=CookX%20Asia%20Sdn.%20Bhd.&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>

                <!-- Old Address -->
                <!-- https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.1108058654795!2d101.60781541540575!3d3.06504155451708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4c84a06833dd%3A0xb6cc2868f1a15375!2sSunway%20Geo%20Flexi%20Office!5e0!3m2!1sen!2smy!4v1629888586604!5m2!1sen!2smy -->
              </div>
              <template v-for="(i, index) in platformInfoList">
                <div :key="index" class="surface--text">
                  <v-card-subtitle
                    class="font-weight-bold text-body-1 text-xl-h6 pb-2 px-0"
                    >{{ $t(`label.${i}`) }}</v-card-subtitle
                  >
                  <v-card-text
                    class="px-0 text-xl-body-1 white-space-pre-line"
                    >{{ platformInfoData[i] }}</v-card-text
                  >
                </div>
              </template>
            </v-card>
          </transition>
        </v-col>
        <!-- Right Col End -->
      </v-row>
    </div>
  </v-img>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import AppFormField from '@/views/components/base/AppFormField';
  import { uiHelper, formValidationHelper, i18nHelper } from '@/core/utils';
  import { ROUTE_NAME } from '@/core/constants';
  import {
    COMPANY_WEB_SUBMIT_CONTACT_APPLICATION,
    COMPANY_WEB_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE
  } from '@/core/store/company-web.module';

  export default {
    name: 'ContactUs',
    components: {
      AppFormField
    },
    mixins: [common],
    data: () => ({
      routeName: ROUTE_NAME,
      formValidation: formValidationHelper,
      formFields: [
        {
          key: 'email',
          value: null,
          rules: formValidationHelper.emailRules(),
          placeholder: 'emailAddress'
        },
        {
          key: 'description',
          value: null,
          rules: formValidationHelper.requiredRules(),
          placeholder: 'tellUsMore',
          type: 'textarea',
          rows: 6
        }
      ],
      platformInfoList: ['email', 'phone', 'address'],
      checkboxAgreement: false,
      isShowContactForm: false,
      isShowFindUsCard: false
    }),
    computed: {
      submitContactApplicationComplete() {
        return this.$store.state.companyWeb.submitContactApplication.complete;
      },
      logoMaxWidth() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 160;
        } else if (this.$vuetify.breakpoint.smOnly) {
          return 120;
        } else {
          return 90;
        }
      },
      platformInfoData() {
        return this.$store.state.platformInfo.platformInfo.data;
      },
      submitButtonFaded() {
        let hasEmptyData = false;

        this.formFields.forEach((x) => {
          if (!x.value) {
            hasEmptyData = true;
          }
        });

        return !this.checkboxAgreement || hasEmptyData;
      }
    },
    watch: {
      submitContactApplicationComplete() {
        let response = this.$store.state.companyWeb.submitContactApplication;

        if (response.complete) {
          this.submitContactApplicationCompleteAction(response);
        }
      }
    },
    mounted() {
      this.insertHref();
    },
    methods: {
      onIntersectFindUsCard(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowFindUsCard = true;
      },
      onIntersectContactForm(entries, observer, isIntersecting) {
        const targetDivider = document.querySelector('#contactUsTitleDivider');

        if (isIntersecting) {
          this.isShowContactForm = true;
          if (targetDivider) {
            targetDivider.classList.add('active');
          }
        }
      },
      submitContactApplicationCompleteAction(response) {
        let type = response.code == 0 ? 'success' : 'error';
        let title = i18nHelper.getMessage('label.contactUs');
        let description = response.message;
        let buttons = [];

        if (response.code == 0) {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.resetForm();
              }
            }
          ];
        } else {
          buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
        }

        this.openAppDialogInfo(type, title, description, buttons);
        this.initialCreateContactApplication();
      },
      submitAction() {
        let data = uiHelper.mapArrayToObjectData(this.formFields);
        data.isAgree = this.checkboxAgreement;

        if (this.$refs.contactForm.validate()) {
          this.createContactApplication(data);
        }
      },
      resetForm() {
        this.$refs.contactForm.reset();
      },
      createContactApplication(data) {
        this.$store.dispatch(COMPANY_WEB_SUBMIT_CONTACT_APPLICATION, { data });
      },
      initialCreateContactApplication() {
        this.$store.dispatch(
          COMPANY_WEB_INITIAL_SUBMIT_CONTACT_APPLICATION_STATE
        );
      },
      insertHref() {
        let id = ['termsOfUse', 'privacyPolicy'];
        let link = {
          termsOfUse: ROUTE_NAME.TERMS_OF_USE,
          privacyPolicy: ROUTE_NAME.PRIVACY_POLICY
        };

        id.forEach((x) => {
          let element = document.getElementById(x);
          element.setAttribute('href', `/support/${link[x]}`);
          element.setAttribute('target', '_blank');
          element.setAttribute('class', 'surface--text');
        });
      }
    }
  };
</script>

<style lang="scss">
  .iframe-google-maps {
    border: 0;
  }
  .contact-us__title-divider {
    border: 1px solid var(--v-primary-base);
    width: 0;
    &.active {
      width: 15%;
      transition: all 1s ease-in-out;
    }
  }

  .contact-us__form-field {
    .theme--light.v-text-field--solo {
      &.v-input--is-focused {
        > .v-input__control > .v-input__slot {
          background: var(--v-surface-base) !important;
        }
      }

      > .v-input__control > .v-input__slot {
        background: rgb(51, 72, 86, 0.8) !important;
      }

      input,
      textarea {
        color: var(--v-surface-base) !important;
        &::placeholder {
          color: rgb(255, 255, 255, 0.4) !important;
        }

        &:focus {
          color: var(--v-secondary-base) !important;
          &::placeholder {
            color: var(--v-secondary-base) !important;
          }
        }
      }
    }

    .v-textarea.v-text-field--rounded {
      border-radius: 6px !important;
    }

    .v-input {
      font-size: 1rem !important;
    }
  }

  .contact-us__checkbox {
    .v-input--selection-controls__input {
      margin-right: 28px !important;
      .v-icon {
        font-size: 30px !important;
      }
    }
    .v-label {
      font-size: 0.875rem !important;
      font-weight: 700 !important;
      color: var(--v-surface-base) !important;
      line-height: 16px !important;
    }
  }

  @media (max-width: 1903px) {
    .contact-us__form-field {
      .v-input {
        font-size: 0.875rem !important;
      }
    }
    .contact-us__checkbox {
      .v-label {
        font-size: 0.7rem !important;
      }
    }
  }

  @media (max-width: 959px) {
    .contact-us .contact-us__submit-button.v-btn {
      font-size: 12px !important;
    }
  }
</style>
